// Seção
.home-blog {
    //background: $cor_secundaria;
}

// Bloco post destaque
.bloco-post-dest {
    position: relative;   
    height: 420px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    // MOBILE
    @include mq(s) {
        height: auto;
        margin-bottom: 20px;
    }

    // TABLET
    @include mq(m) {
        height: 360px;
    }

    // Foto
    figure {
        overflow: hidden;
    
        img {
            transition: .3s ease-out;
        }
    }
    &:hover figure img {
        transform: scale(1.1);
    }

    // Descrição
    > div {
        padding: 10px 20px;

        // TABLET
        @include mq(m) {
            padding: 10px;
        }

        // MOBILE
        @include mq(s) {
            padding: 10px;
        }
    }

    // Título
    h3 {
        font-size: 16px;
        line-height: 22px;
        color: $cor_fonte_escura;
        font-weight: bold;
        margin-bottom: 10px;
        transition: .3s;

        // TABLET
        @include mq(m) {
            font-size: 16px;
            line-height: 24px;    
        }
    }
    &:hover h3 {
        color: $cor_primaria;
    }

    // Texto
    span {
        color: $cor_fonte_clara;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        transition: .3s;

        // TABLET
        @include mq(m) {
           font-size: 14px; 
           line-height: 21px;      
        }
    }
    &:hover span {
        color: $cor_fonte_escura;
    }
}