// Foto
.servico-detalhe-foto {
    margin-right: 10px;
    margin-bottom: 10px;

	// MOBILE
	@include mq(s) {
        margin-right: 10px;
		margin-bottom: 20px;
	}
}

// Texto
.servico-detalhe-texto {

	ul{
		list-style: initial;
		padding-left: 30px;
	}

	// BOTÃO
	.btn-container {
		float: none;

		// MOBILE
		@include mq(s) {
			text-align: center;
		}
	}
}

