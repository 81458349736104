// Slide
.slide-home {
	float: left;
	width: 100%;
	
	// Modifica as cetas do SLICK para o slide
	.slick-prev,
	.slick-next {	    
		height: 35px;
		width: 35px;	 
		/* border-color: rgba(#FFF, .5); 
		&:hover, &:focus {	      
			border-color: $cor_primaria;
		}
		&.slick-disabled {
			border-color: rgba(#FFF, .2);
        	cursor: default !important;
		} */
		
		// MOBILE
		@include mq(s) {	
			display: none !important;
		}
	}
	.slick-prev {
		left: 20px;
	}
	.slick-next {
		right: 20px;
	}	
}

// Slide ITEM (Background Image)
.slide-item {
	position: relative;
	display: block;
	float: left;
	width: 100%;
	height: auto;
	overflow: hidden;
	background: #FFF;
	text-align: center;
	
	&[href="#"] {
		cursor: default;
	}

	// Slide IMAGEM
	.slide-image {	
		width: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 100vh;

		// TABLET
		@include mq(m) {
			height: 100vh;
			min-height: 600px;
		}

		// MOBILE
		@include mq(s) {
			height: 80vh;
			min-height: 400px;
		}
	}
}

// Slide ITEM (Imagem padrão) OBS: Está desabilitado por padrão
.slide-item-auto {
	position: relative;
	display: block;
	float: left;
	width: 100%;
	height: auto;
	overflow: hidden;
	background: #FFF;
	text-align: center;

	&[href="#"] {
		cursor: default;
	}

	// Slide IMAGEM
	.slide-image {	
		display: inline-block;
		vertical-align: bottom;
	}
}

// Slide MÁSCARA
.slide-mask {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(#000, .1);	
	display: flex; 
	align-items: center; 
	justify-content: center;
}

// Slide CONTEÚDO
.slide-content {
	width: auto;
	max-width: 1000px; 
	display: none; 
	background: rgba(#FFF, .5);
	padding: 30px;
	margin-top: 40px;

	// TABLET
	@include mq(m) {
		max-width: 700px; 
		padding: 20px;
		margin-top: 150px;
	}

	// MOBILE
	@include mq(s) {
		padding-top: 180px;
		max-width: 300px;  
		padding: 20px 10px;
		margin-top: 120px;
	}

	.btn {
		// MOBILE
		@include mq(s) {
			font-size: 12px;
			padding: 10px 20px;
		}
	}
}

// Slide TÍTULO
.slide-titulo {	
	width: 100%; 
	text-align: center;
	display: block; 
	font-family: $fonte_geral;	
	text-transform: uppercase; 
	color: $cor_fonte_escura; 
	text-align: center;	
	font-weight: bold;	
	font-size: 28px; 
	line-height: 34px;
	margin-bottom: 30px;
	position: relative;

	// Linha inferior
	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -10px;
		margin: 0 auto;
		width: 100px;
		height: 3px;
		background: $cor_primaria;
	}
	
	// MOBILE
	@include mq(s) {
		font-size: 16px; 
		line-height: 24px;
	}
	
	// TABLET
	@include mq(m) {			
		font-size: 24px; 
		line-height: 30px;
	}
}

// Slide TEXTO
.slide-texto {	
	width: 100%; 
	text-align: center;
	display: block; 
	font-family: $fonte_geral;	
	text-transform: none; 
	color: $cor_fonte_escura; 
	text-align: center;		
	font-size: 18px; 
	line-height: 28px;
	font-weight: 600;
	margin-bottom: 30px;

	// MOBILE
	@include mq(s) {
		font-size: 13px; 
		line-height: 18px;
		
	}
	
	// TABLET
	@include mq(m) {			
		font-size: 16px; 
		line-height: 24px;
	}
}

// Animação do texto
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
