// Barra principal
.fdx-menu.lateral {
	float: right;
	width: auto;	
	text-align: left;

	.container {
		position: static;
	}

	li {
		display: block;
		float: left;
		position: relative;
	}

	// Links geral
	a {
		display: inline-block;
		width: 100%;		
		font-family: $fonte_geral;
		font-size: 16.3px;
		font-weight: normal;
		transition: .3s ease-out;
		font-weight: 600;

		// TABLET
		@include mq(m) {
			font-size: 12px;
		}

		&:hover {
			color: #333;
		}
	}

	// MOBILE
	@include mq(s) {			
		display: none;
	}
}

/// 1 Level
.lateral .fdx-menu-1-level {
	float: left;
	width: 100%;

	// Item
	> li {
		padding: 0 22.5px;

		// TABLET
		@include mq(m) {
			padding: 0 5px;
		}

		// Remove o padding do primeiro link
		&:first-child {
			padding-left: 0px;
		}

		// Remove o padding do último link
		&:last-child {
			padding-right: 0px;
		}

		// Link
		> a {
			position: relative;
			z-index: 2;
			color: #333;
			text-transform: uppercase;

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: -6px;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 100%;
				height: 1px;
				background: $cor_primaria;
				transform: translate(0, -10px);
				opacity: 0;
				transition: .3s ease;

				// TABLET
				@include mq(m) {
					top: -3px;
				}

			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				bottom: -5px;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 100%;
				height: 1px;
				background: $cor_primaria;
				transform: translate(0, 10px);
				opacity: 0;
				transition: .3s ease;

				// TABLET
				@include mq(m) {
					bottom: -3px;
				}
			}
			&:hover::before, &:hover::after {
				transform: translate(0, 0);
				opacity: 1;
			} 
		}
	}	
}

// 2 Level
.lateral .fdx-menu-2-level {
	position: absolute;
	top: 50px;
	left: 0;
	z-index: 1;
	width: 300px;	
	padding: 10px 0;
	background: rgba($cor_secundaria, .98);
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	border-radius: 0px;
	opacity: 0;
	visibility: hidden;
	transform: translate(0,-20px);
	transition: .3s ease;
	border-top: 3px solid $cor_primaria;

	// TABLET
	@include mq(m) {
		top: 40px;
		width: 320px;
		padding: 5px 0;
	}

	// Ponta
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: -15px;
		left: 50px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 20px 15px 20px;
		border-color: transparent transparent $cor_primaria transparent;

		// TABLET
		@include mq(m) {
			top: -10px;
			border-width: 0 15px 10px 15px;
		}
	}

	// Item
	li {
		float: left;
		width: 100%;

		// Link
		a {
			padding: 5px 20px;
			color: #FFF;
			font-size: 16px;
			text-transform: none;

			&:hover {
				padding-left: 30px;
				color: $cor_primaria;
			}

			// TABLET
			@include mq(m) {
				padding: 3px 10px;
				font-size: 14px;
			}
		}
	}
}
.lateral .fdx-menu-1-level > li:hover > .fdx-menu-2-level {
	opacity: 1;
	visibility: visible;
	transform: translate(0,0);
}

// 3 Level
.lateral .fdx-menu-3-level {
	position: absolute;
	top: -10px;
	right: -250px;
	width: 250px;
	padding: 10px 0;
	background: #222;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	opacity: 0;
	visibility: hidden;
	transform: translate(-20px,0);
	transition: .3s ease;

	// TABLET
	@include mq(m) {
		right: -200px;
		width: 200px;
		top: -5px;
		padding: 5px 0;
	}

	// Item
	li {
		float: left;
		width: 100%;

		// Link
		a {
			padding: 5px 20px;
			color: #FFF;
			text-transform: uppercase;

			// TABLET
			@include mq(m) {
				padding: 0px 10px;
			}
		}
	}
}
.lateral .fdx-menu-2-level > li:hover > .fdx-menu-3-level {
	opacity: 1;
	visibility: visible;
	transform: translate(0,0);
}