// Seção
.blocos-home {
    padding-top: 30px;    
}

// Carrosel
/* .carrosel-blocos {
    .slick-prev,
	.slick-next {	  	 
		border-color: rgba(#FFF, .5); 
		&:hover, &:focus {	      
			border-color: $cor_primaria;
		}
		&.slick-disabled {
			border-color: rgba(#FFF, .2);
		}
	}
} */

// Bloco item
.bloco-home {
    display: block;
    float: left;
    width: 100%;
    margin: 20px 20px;
    text-align: center;

    // MOBILE
    @include mq(s) {
        margin: 10px 10px;
    }

    // TABLET
    @include mq(m) {
        margin: 20px 10px;
    }

    // Ícone
    .bloco-home-icon {
        position: relative;
        display: inline-block;
        background: rgba(#000, .1);
        width: 120px;
        height: 120px;
        line-height: 145px;
        border-radius: 50%;
        text-align: center;
        margin-bottom: 20px;
        transition: .3s ease-out;   

        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background: rgba($cor_primaria, .5);
            opacity: 0;            
        }

        i {
            font-size: 50px;
            color: $cor_primaria;
            transition: .3s ease-out;
        }

        img {
            display: inline-block;
            max-width: 70px;
            max-height: 70px;
        }
    }
    &:hover .bloco-home-icon::before {
        animation: bloco-pulse .5s;
    }

    // Título
    .bloco-home-titulo {
        font-family: $fonte_geral;
        color: $cor_fonte_escura;
        text-transform: none;
        font-size: 18px;
        line-height: 26px;
        font-weight: bold;
        text-transform: none;
        margin-bottom: 10px;
    }

    // Texto
    .bloco-home-texto {
        color: $cor_fonte_clara; 
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        transition: .3s;
    }
    /* &:hover .bloco-home-texto {
        color: $cor_fonte_escura;
    } */

    // Botão
    .btn-container {
        margin-top: 0px;
    }
}

@keyframes bloco-pulse {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: scale(1.3);
    }
}