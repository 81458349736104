// ------------------------------------
//	LIB
// ------------------------------------

// Slick (Carrosel)
@import 'lib/slick';
// Lightbox (Zoom imagens)
@import 'lib/lightbox';
// Aos (Animações do scroll)
@import 'lib/aos';

// ------------------------------------
//	GERAIS
// ------------------------------------

// Reset
@import 'gerais/reset';
// Mixins (Funções)
@import 'gerais/mixins';
// Grid
@import 'gerais/grid';
// Geral
@import 'gerais/geral';
// Loadings
@import 'gerais/loading';
// Modal
@import 'gerais/modal';
// Tabs
@import 'gerais/tabs';
// Carrosel
@import 'lib/slick-theme';
// Datepicker
@import 'lib/flatpickr';

// ------------------------------------
//	ESTRUTURA
// ------------------------------------

// Header
@import 'estrutura/header';
// Menu lateral
@import 'estrutura/menu_lateral';
// Menu Mobile
@import 'estrutura/menu_mobile';
// Footer
@import 'estrutura/footer';
// Paginação
@import 'estrutura/paginacao';
// Manutenção
@import 'estrutura/manutencao';

// ------------------------------------
//	HOME
// ------------------------------------

// Slide
@import 'home/slide';
// Blocos
@import 'home/blocos';
// Sobre
@import 'home/sobre';

// ------------------------------------
//	INSTITUCIONAL
// ------------------------------------

// Páginas
@import 'institucional/paginas';
// Contato
@import 'institucional/contato';

// ------------------------------------
//	SERVIÇOS
// ------------------------------------

// Serviço detalhe
@import 'servicos/detalhe';

// ------------------------------------
//	BLOG
// ------------------------------------

// Posts
@import 'blog/posts';
// Destaques
@import 'blog/destaques';
// Lateral
@import 'blog/lateral';
// Detalhe
@import 'blog/detalhe';

// ------------------------------------
//	SÓCIOS/EQUIPE
// ------------------------------------

// Sócios/Equipe
@import 'sociosequipe/sociosequipe';

// ------------------------------------
//	GALERIA
// ------------------------------------

// Galeria
@import 'galeria/galeria';

// ------------------------------------
//	INFORMATIVOS
// ------------------------------------

// Informativos
@import 'informativos/informativos';
