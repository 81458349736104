.informativos-lista{
	float: left;
	width: 100%;
}

.item-informativo{
	margin-bottom: 20px;

	// Foto
	figure {
		float: left;
		margin-bottom: 10px;
		overflow: hidden;

		img {
			transition: .3s ease-out;
		}
	}
	&:hover {
		figure img {
			transform: scale(1.1);
		}
	}

	// Título
	.titulo-informativo {
		float: left;
		height: 24px;
		width: 100%;		
		margin-bottom: 10px;
		color: $cor_fonte_escura;
		font-size: 18px;
		transition: .3s ease-out;
		overflow: hidden;

		// MOBILE
		@include mq(s) {
			font-size: 16x;
		}
	}
	&:hover {
		.titulo-informativo  {
			color: $cor_primaria;
		}
	}

	.texto-informativo{
		float: left;
		height: 62px;
		width: 100%;		
		margin-bottom: 5px;
		color: $cor_fonte_clara;
		font-size: 14px;
		line-height: 20px;
		text-align: justify;
		transition: .3s ease-out;
		overflow: hidden;

		// MOBILE
		@include mq(s) {
			font-size: 13px;
		}
	}

	.data-informativo{
		float: left;
		font-size: 14px;
		color: $cor_fonte_clara;

		// MOBILE
		@include mq(s) {
			font-size: 13px;
		}
	}
}
