.socios-lista {
    float: left;
    width: 100%;
}

// Item
.socio-caixa {
    margin-bottom: 80px;
    background: #fff;
    position: relative;
    //padding: 20px;
    //box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    // MOBILE
    @include mq(s) {
       text-align: center; 
    }

    // Linha inferior
	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		bottom: -40px;
		width: 50%;
		height: 2px;
		background: #DDD;
    }
    // ALINHAMENTO (DIREITA)
    &:nth-child(even)::after {
        left: inherit;
        right: 0;
    }

    // Foto
    figure {
        float: left;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: 3px solid #CCC;
        margin-right: 40px;
        overflow: hidden;

        // MOBILE
        @include mq(s) {
            float: none !important;
            display: inline-block;
            margin-right: 0px !important;
            margin-left: 0px !important;
            margin-bottom: 20px;
        }
    }
    // ALINHAMENTO (DIREITA)
    &:nth-child(even) figure {
        float: right;
        margin-right: 0px;
        margin-left: 40px;
    }

    // Infos
    > div {
        float: left;
        text-align: left;
        width: calc(100% - 240px);

        // MOBILE
        @include mq(s) {
            width: 100%;
            text-align: left !important;
        }
    }
    // ALINHAMENTO (DIREITA)
    &:nth-child(even) div {
        float: right;
        text-align: right;
    }

    // Nome
    h2 {
        font-family: $fonte_titulo;
        color: $cor_primaria;
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: bold;

        // MOBILE
        @include mq(s) {
            margin-top: 0px;
            text-align: center;
        }
    }

    // Descrição
    h3 {      
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        color: $cor_fonte_escura;
    }
}