// Header principal
.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z_index_header;
	float: left;
	width: 100%;
	background: #FFF;
	padding-top: 15px;
	padding-bottom: 15px;
	box-shadow: 0 1px 3px rgba(0,0,0,.26);
	transition: .5s ease;

	// TABLET
	@include mq(m) {	
		text-align: center;			
		padding: 10px 0;
	}
	
	// MOBILE
	@include mq(s) {
		position: absolute !important;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;
	}
}

// Logo
.header-logo {
	display: block;
	float: left;
	max-width: 278px;
	width: auto;

	// MOBILE
	@include mq(s) {	
		float: none;
		display: inline-block;
		vertical-align: top;
		max-width: 180px;
	}

	// TABLET
	@include mq(m) {				
		float: left;
		display: inline-block;
		vertical-align: top;
		max-width: 160px;
	}
}

// Direita
.header-direita {
	float: right;
	width: auto;
	margin-top: 10px;
	
	// TABLET
	@include mq(m) {			
		margin-top: 0px;
	}

	// MOBILE
	@include mq(s) {
		width: 100%;				
		margin-top: 10px;
	}
}

// Endereço 
.endereco-header{
	float: right;
	color: $cor_fonte_escura;
	margin-top: 1px;
	font-size: 15px;
	margin-left: 0.5px;

	// TABLET
	@include mq(m) {				
		font-size: 12px;
	}

	// MOBILE
	@include mq(s) {
		width: 100%;				
		margin-top: 0px;		
		font-size: 11px;
	}
}

// Telefones
.header-telefones {
	text-align: left;
	margin-bottom: 30px;

	// TABLET
	@include mq(m) {
		width: 100%;
		text-align: right;
		margin-bottom: 10px;
	}

	// MOBILE
	@include mq(s) {
		float: left;
		width: 100%;
		text-align: center;
		margin-bottom: 0px;
	}

	// Item
	a {
		display: inline-block;				
		margin-right: 20px;		
		font-family: $fonte_menu;
		font-weight: normal;
		font-size: 18px;
		text-align: right;
		text-transform: uppercase;
		color: $cor_fonte_escura;

		// TABLET
		@include mq(m) {				
			font-size: 12px;
			margin: 0 0px;
			padding-right: 10px;
		}

		// MOBILE
		@include mq(s) {
			text-align: center;
			margin: 0px;
			margin-top: 5px;	
			font-size: 12px;
		}

		i {
			margin-right: 5px;
			font-size: 22px;
			color: $cor_primaria;
			vertical-align: middle;

			// TABLET
			@include mq(m) {				
				font-size: 18px;
			}

			// MOBILE
			@include mq(s) {
				font-size: 14px;
			}
		}
	}
}

.header-botao{
	width: auto;
	float: left;

	// TABLET
	@include mq(m) {		
		width: 100%;
		margin-bottom: 30px;
	}

	// MOBILE
	@include mq(s) {	
		width: 100%;
		margin-bottom: 30px;
	}

	i{
		font-size: 16px;
	}
}

// ---------------------------- HOME ---------------------------- //

body.home {
	padding-top: 0px;
}

// Header
.home .header:not(.active) {
	background: rgba(#FFF, .9);
	box-shadow: none;
	padding-top: 20px;
	padding-bottom: 20px;
	
	// MOBILE
	@include mq(s) {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

// Logo
/* .home .header:not(.active) .header-logo {
	
	// Logo COLORIDO
	&.colored-logo {
		display: none;
	} 

	// Logo BRANCO
	&.white-logo {
		display: block;

		// TABLET
		@include mq(m) {
			display: inline-block;
		}

		// MOBILE
		@include mq(s) {
			display: inline-block;
		}
	}
}*/

// Telefones
/* .home .header:not(.active) .header-telefones a {
	color: #FFF;
} */

// Botão
/* .home .header:not(.active) .btn-outline {
	border: 1px solid #FFF;
	color: #FFF;

	&:hover, &:focus, &:active, &.active {
		background: #FFF;
		color: $cor_primaria;
	}


	i &.active{
			margin-right: 5px;
			font-size: 22px;
			color: #fff;
			vertical-align: middle;

			// TABLET
			@include mq(m) {				
				font-size: 18px;
			}

			// MOBILE
			@include mq(s) {
				font-size: 18px;
			}
		}
} */


// Menu
/* .home .header:not(.active) .lateral .fdx-menu-1-level > li > a {
	color: #FFF;

	&:hover {
		color: #FFF;
	}
}
.home .header:not(.active) .menu-btn-mobile {
   	border-color: #FFF;

    &::before, &::after {
        background: #FFF;
    }
} */