// ------------------------------------
//	VARIÁVEIS
// ------------------------------------

// Cores do site
$cor_primaria: #916520;
$cor_secundaria: #222222;
$cor_terciaria: #A4A4A4;

// Fundo 2 (Cor alternativa para o fundo do site)
$cor_fundo2: #E5E5E5;

// Status
$success_color: #00C851;
$warning_color: #FFBB33;
$error_color: #FF4444;

// Redes socias
$cor_facebook: #3B5998;
$cor_twitter: #1DA1F2;
$cor_youtube: #FE0000;
$cor_instagram: #C12595;
$cor_twitter: #1DA1F2;
$cor_tripadvisor: #83B957;

// ------------------------------------
//	FONTES
// ------------------------------------

// Fonte destaque (TÍTULOS)
$fonte_titulo: 'Raleway', 'Helvetica', 'Arial', sans-serif;

// Fonte geral (TEXTO)
$fonte_geral: 'Raleway', 'Helvetica', 'Arial', sans-serif;

// Fonte menu
$fonte_menu: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;

// Cores da fonte
$cor_fonte_escura: #333;
$cor_fonte_clara: #444;

// Z-index (Z-INDEX de cada elemento do layout)
$z_index_geral: 2;
$z_index_menu: 4;
$z_index_header: 5;
$z_index_gotop: 5;
$z_index_menu_mobile: 6;
$z_index_modal: 7;
$z_index_mascara: 8;

// ------------------------------------
//	GERAIS
// ------------------------------------

// Box-sizing
*, *::after, *::before {
	box-sizing: border-box;
}

// Remove outline de foco do firefox e outros browsers
:focus {
	outline:none;
}
::-moz-focus-inner {
	border:0;
}

// Cor do texto selecionado
::selection {
	background: rgba($cor_primaria, .5);
}
::-moz-selection {
	background: rgba($cor_primaria, .5);
}

// Remove o fundo azul de itens clicados no chrome para android
* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

// Body
body {
	background: #FFF;
	font-family: $fonte_geral;
	font-size: 16px;
	line-height: 24px;
	color: $cor_fonte_clara;
	padding-top: 113px;

	// TABLET
	@include mq(m) {
		padding-top: 79px;
	}

	// MOBILE
	@include mq(s) {
		padding-top: 139px;
	}
}

// Fix imagens
img {
	display: block;
	max-width: 100%;
}

// Wrapper
/* Elemento que segura todo o conteúdo do site (exceto o footer)
Utilizado para impedir que o footer suba quando há pouco conteúdo
ou quando a resolução da tela é maior. */
.wrapper {
	min-height: calc(100vh - 380px);

	// TABLET
	@include mq(m) {
		min-height: calc(100vh - 460px);
	}

	// MOBILE
	@include mq(s) {
		min-height: 100vh;
	}
}

// Seções
.secao {
	float: left;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	position: relative;

	// MOBILE
	@include mq(s) {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	// Corrige o bug do scroll horizontal nas animações
	.container {
		overflow: hidden;
		padding-top: 5px;
		padding-bottom: 5px;
	}
}

// ------------------------------------
//	TEXTOS
// ------------------------------------

// Título
.titulo {
	float: left;
	width: 100%;
	position: relative;
	font-family: $fonte_geral;
	font-size: 28px;
	line-height: 42px;
	font-weight: bold;
	color: $cor_fonte_escura;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 60px;

	// TABLET
	@include mq(m) {
		font-size: 24px;
		line-height: 36px;
	}

	// MOBILE
	@include mq(s) {
		font-size: 20px;
		line-height: 30px;
	}

	// Linha inferior
	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -15px;
		margin: 0 auto;
		width: 100px;
		height: 3px;
		background: $cor_primaria;
	}
}

// Título 2
.titulo2 {
	float: left;
	width: 100%;
	font-size: 20px;
	font-family: $fonte_geral;
	font-weight: bold;
	color: $cor_fonte_escura;
	text-transform: none;
	margin-bottom: 40px;
	padding-bottom: 10px;
}

// Subtítulo
.subtitulo {
	font-family: $fonte_geral;
	font-size: 24px;
	color: $cor_fonte_escura;
	text-align: center;
	margin-top: -20px;
	margin-bottom: 60px;
}

// Texto geral
.texto {
	font-family: $fonte_geral;
	font-size: 16px;
	line-height: 24px;
	color: $cor_fonte_clara;

	&.center {
		text-align: center;
	}

	b, strong {
		font-weight: bold !important; 
		color: $cor_fonte_escura !important;
	}
}

// Texto (Sem registros)
.empty {
	text-align: center;
	min-height: 100px;

	span {
		font-family: $fonte_geral;
		font-size: 24px;
		color: $cor_fonte_escura;
		margin-bottom: 10px;
	}

	p {
		font-family: $fonte_geral;
		color: $cor_fonte_clara;
	}

	b {
		font-weight: bold;
		color: $cor_fonte_escura;
	}
}

// Links
a, a:hover, a:visited, a:focus, a:active {
	outline: none;
	text-decoration: none;
}
.link {
	font-family: $fonte_geral;
	transition: .2s; 
	color: $cor_primaria;

	&:active, &:focus, &:visited { 
		color: $cor_primaria;
	}

	&:hover {
		color: $cor_secundaria !important;
	}
}

// ------------------------------------
//	FORMULÁRIOS
// ------------------------------------

// Container
.campo-container {
	position: relative;
	margin-bottom: 20px;

	// Label
	label {
		display: inline-block;
		font-weight: bold;
		color: $cor_fonte_escura;
		margin-bottom: 5px;
	}
}

// Input
.campo {
	width: 100%;
	border: none;
	border-bottom: 2px solid #DDD;
	border-radius: 0px;
	background: #FFF;
	outline: none;
	box-shadow: none;
	height: 45px;
	padding-left: 0px;
	padding-right: 0px;
	font-family: $fonte_geral;
	font-size: 16px;
	font-weight: normal;
	color: $cor_fonte_escura;
	@include placeholder($cor_fonte_escura);
	transition: .3s;

	&:focus {
		border-color: $cor_primaria;
	}

	&[disabled] {
		opacity: .5;
		cursor: default;
	}
}

// Textarea
textarea.campo {
	height: auto;
	padding: 15px 0;
	resize: none;
}

// Arquivo
.campo.file {
	padding: 0px;
	border: none;
	background: none;
}

// Select
select.campo {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;	

	&::-ms-expand {
		display: none;
	}

	&:hover + .arrow, &:focus + .arrow  {
		border-top-color: $cor_fonte_escura;
	}
}
// Arrow (Select)
.campo-container .arrow {
	position: absolute;
	bottom: 18px;
	right: 15px;
	width: 0;
	height: 0;
	pointer-events: none;
	border-style: solid;
	border-width: 8px 5px 0 5px;
	border-color: $cor_fonte_clara transparent transparent transparent;
}

// Radio / Checkbox
.campo-container.cr-container {
	width: auto;
	> label:not(.cr-lbl) {
		width: 100%;
		margin-bottom: 10px;
	}

	// Item
	.cr-lbl {
		display: inline-block;
		margin: 0px;
		margin-bottom: 5px;
		margin-right: 10px;
		padding: 5px 0;
		cursor: pointer;

		// MOBILE
		@include mq(s) {
			width: 100%;
		}

		&:last-child {
			margin-right: 0px;
		}

		input {
			display: none;
			opacity: 0;
			visibility: hidden;
			z-index: -1;
		}

		// Radio
		.radio {
			position: relative;
			display: block;
			float: left;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 2px solid #DDD;
			background: #DDD;
			margin-right: 5px;
			margin-top: -2px;
			text-align: center;
			transition: .2s;
		}
		&:hover .radio {
			border-color: $cor_primaria;
		}
		input:checked + .radio {
			background: $cor_primaria;
			border-color: $cor_primaria;

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 3px;
				left: 5.5px;
				width: 5px;
				height: 8px;
				border-right: 2px solid #FFF;
				border-bottom: 2px solid #FFF;
				transform: rotate(45deg);
			}
		}

		// Checkbox
		.checkbox {
			position: relative;
			display: block;
			float: left;
			width: 20px;
			height: 20px;
			border: 2px solid #DDD;
			background: #DDD;
			margin-right: 5px;
			margin-top: -2px;
			text-align: center;
			transition: .2s;
		}
		&:hover .checkbox {
			border-color: $cor_primaria;
		}
		input:checked + .checkbox {
			background: $cor_primaria;
			border-color: $cor_primaria;

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 3px;
				left: 5.5px;
				width: 5px;
				height: 8px;
				border-right: 2px solid #FFF;
				border-bottom: 2px solid #FFF;
				transform: rotate(45deg);
			}
		}

		// Texto
		> span {
			display: block;
			float: left;
			width: auto;
			margin-top: -3px;
			font-size: 16px;
			font-weight: bold;

			// MOBILE
			@include mq(s) {
				float: none;
			}
		}
		input:checked ~ span {
			color: $cor_fonte_escura;
		}
		input[disabled] ~ span {
			opacity: .5;
			cursor: default;
		}
	}
}

// Contador de caracter
.caracter_count {
	position: absolute;
	bottom: -15px;
	right: 0;
	font-size: 14px;
	color: $cor_fonte_clara;
}

// Loading
.campo-loading {
	display: none;
	position: absolute;
	bottom: 10px;
	right: 10px;

	i {
		font-size: 24px;
		color: rgba(#000, .5);
	}
}
.campo-loading + .campo {
	padding-right: 50px;
}

// Validação de formulário
.show-validacao.valid::after {
	content: "\f058";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: $success_color;
	font-size: em(20);
	position: absolute;
	z-index: 2;
	bottom: 10px;
	right: 10px;
}
.parsley-errors-list {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;

	li {
		z-index: $z_index_geral;
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		display: block;
		margin-top: 10px;
		padding: 10px;
		background: $error_color;
		color: #FFF;
		font-size: 14px;
		text-align: center;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 
		0 3px 6px rgba(0,0,0,0.23);

		// Ponta
		&::before {
			content: '';
			display: block;
			position: absolute;
			top: -8px;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent $error_color transparent;
		}
	}
}

// ------------------------------------
//	BOTÕES
// ------------------------------------

// Botão padrão
.btn {
	display: inline-block;
	vertical-align: middle;
	width: auto;
	background: rgba(#000, .1);
	border: none;
	border-radius: 5px;
	font-family: $fonte_titulo;
	font-size: 16px;
	font-weight: normal;
	line-height: 100%;
	text-transform: uppercase;
	color: $cor_fonte_escura;	
	text-align: center;
	padding: 14px 30px;
	outline: none;
	cursor: pointer;	
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: all;
	transition-property: all;

	&:hover, &:focus, &:active, &.active {
		background: rgba(#000, .15);
	}

	// Inativo
	&[disabled] {
		opacity: .5;
		cursor: default;
	}

	i {
		margin-right: 10px;
	} 

	// Botão primário
	&.btn-primario {
		background: $cor_primaria;
		color: #FFF;

		&:hover, &:focus, &:active, &.active {
			background: darken($cor_primaria, 10%);
		}

		&.active:hover {
			background: $cor_primaria;
		}
	}

	// Botão secundário
	&.btn-secundario {
		background: $cor_secundaria;
		color: #FFF;

		&:hover, &:focus, &:active, &.active {
			background: darken($cor_secundaria, 10%);
		}

		&.active:hover {
			background: $cor_secundaria;
		}
	}

	// Botão blocos home
	&.btn-blocos {
		background: rgba(#FFF, .1);
		color: #FFF;

		&:hover, &:focus, &:active, &.active {
			background: rgba(#FFF, .2);
		}

		&.active:hover {
			background: rgba(#FFF, .2);
		}
	}

	// Botão pequeno
	&.btn-sm {
		padding: 10px 20px;
		font-size: 14px;
	}

	// Botão grande
	&.btn-lg {
		padding: 20px 40px;
		font-size: 20px;
	}
}

// Botão container
.btn-container {
	float: left;
	width: 100%;
	margin-top: 20px;
	text-align: center;

	&.left {
		text-align: left;
	}

	&.right {
		text-align: right;
	}
}

// ------------------------------------
//	VOLTAR AO TOPO
// ------------------------------------
.gotop {
	position: fixed;
	z-index: $z_index_gotop;
	bottom: 20px; 
	right: 20px;
	width: 50px; 
	height: 50px;
	border-radius: 50%; 
	background: rgba($cor_primaria,.8);
	cursor: pointer;
	box-shadow: 0px 3px 5px rgba(0,0,0,0.3); 
	transform: translate(0,100px);
	opacity: 0;
	visibility: hidden;
	transition: .3s ease-out;

	&:hover { 
		background: rgba($cor_primaria,1);
	}

	&.active { 
		transform: translate(0,0);
		opacity: 1;
		visibility: visible;
	}

	&::before { 
		content: ''; 
		position: absolute; 
		top: 0px; 
		bottom: 0px; 
		left: 0px; 
		right: 0px; 
		margin: auto; 
		display: block; 
		width: 15px; 
		height: 15px; 
		border: 2px solid #FFF; 
		transform: rotate(45deg); 
		border-bottom: none; 
		border-right: none; 
		margin-top: 20px; 
	}
}

.section-divider {
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	height: 50px;
	background: none;

	// MOBILE
	@include mq(s) {
		display: none;
	}
	
	&.up {
		margin-top: -49px;
	}

	&.down {
		margin-bottom: -49px;
	}

	svg path {
		fill: #FFF;
	}
	&.divider-footer svg path {
		fill: #FFF;
	}

	&.circle {
		height: 50px;

		&.up {
			margin-top: -49px;
		}

		&.down {
			margin-bottom: -49px;
		}
	}
}