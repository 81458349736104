// Banner do topo
.institucional-banner {
	float: left;
	width: 100%;
	height: 220px;
	position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

	// Máscara
    .mascara {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      
    }

	// TABLET
	@include mq(m) {
		height: 170px;
	}

	// MOBILE
	@include mq(s) {
		height: 170px;
	}
}

/* Segura o texto das páginas instituicionais para evitar que fiquem com 100% de largura e prejudique a leitura */
.segura-texto {
	// DESKTOP
	@include mq(l) {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}	
}
