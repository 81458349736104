// Seção
.home-sobre {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;

    // Máscara
    .mascara {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba($cor_fundo2, .2);
    }
}

// Bloco
.home-sobre-bloco {
    float: left;
    width: 100%;
    padding: 40px;
    background: #FFF;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    // MOBILE
    @include mq(s) {
        padding: 20px;
    }
}

// Texto
.home-sobre-texto {
    padding-right: 10px;
    
    // MOBILE
    @include mq(s) {
        padding-right: 0px;
        margin-bottom: 40px;
    }

    // Texto
    .texto {
        text-align: justify;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        color: $cor_fonte_clara;
    }

    // Botão
    .btn-container {
        margin-top: 40px;

        // MOBILE
        @include mq(s) {
            text-align: center !important;
        }
    }
}

// Foto
.home-sobre-foto {
    overflow: hidden;
    
    img {
        transition: .3s ease-out;
    }
    &:hover img {
        transform: scale(1.1);
    }

        // MOBILE
    @include mq(s) {
        padding-right: 0px;
        margin-bottom: 20px;
    }
}

// ALTERATIVA
.home-sobre2 {
    background: $cor_fundo2;
}
// Fotos
.home-sobre-fotos {
    float: left;
    width: 100%;
    padding-top: 0px;

    // Foto
    .thumb {
        display: block;
        width: 100%;
        overflow: hidden;
        margin: 2px;

        img {
            transition: .5s ease-out;
        }
        &:hover img {
            transform: scale(1.2);
        }
    }
}