// Footer geral
.footer {
	position: relative;
	height: 380px;
	padding-top: 40px;
	padding-bottom: 40px;
	background: $cor_fundo2;
	overflow: hidden;

	// TABLET
	@include mq(m) {
		height: 460px;
	}

	// MOBILE
	@include mq(s) {
		height: auto;	
		padding-bottom: 0px;
	}
}

// Coluna
.footer-bloco {
	// Título
	> h2 {
		font-family: $fonte_titulo;
		color: $cor_primaria;
		font-size: 24px;
		line-height: 36px;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 20px;

		// MOBILE
		@include mq(s) {
			text-align: left;
		}
	}
}

// Logo
.footer-logo {
	text-align: left;

	.logo {
		display: inline-block;
		max-width: 250px;
		margin-bottom: 20px;
	}

	h3 {
		color: #666;
		font-size: 13px;
		line-height: 17px;
		text-align: justify;
	}

	// MOBILE
	@include mq(s) {
		text-align: center;
		margin-bottom: 40px;
		width: 50%;
		display: none;
	}
}

// Serviços
.footer-servicos {
	padding-top: 20px;
	padding-left: 20px;

	// TABLET
	@include mq(m) {
		padding-top: 0px;
		padding-left: 0px;
	}

	// MOBILE
	@include mq(s) {
		padding-top: 0px;
		padding-left: 0px;
		margin-bottom: 40px;
	}

	// Lista
	ul {
		float: left;
		width: 100%;

		// Item
		li {
			float: left;
			width: 50%;
			margin-bottom: 10px;

			// TABLET
			@include mq(m) {
				width: 100%;
			}

			// MOBILE
			@include mq(s) {
				width: 100%;
				text-align: left;
			}
			
			// Link
			a {
				font-size: 14px;
				line-height: 24px;
				font-weight: normal;
				color: #666;
				transition: .3s;

				&:hover {
					color: $cor_primaria;
				}
			}
		}
	}
}

.ellos{ color: #666 !important }

// Atendimento
.footer-atendimento {
	padding-top: 20px;

	// TABLET
	@include mq(m) {
		padding-top: 0px;
	}

	// MOBILE
	@include mq(s) {
		padding-top: 0px;
		text-align: left;
	}

	// Texto
	.texto {
		color: $cor_fonte_escura;
	}

	// Itens
	li {
		display: inline-block;
		width: 100%;
		margin-bottom: 10px;
		color: #666;
		font-size: 14px;
		line-height: 24px;
		
		// Ícone
		i {
			margin-right: 5px;
			font-size: 18px;
			color: $cor_fonte_escura;
		}

		a {
			color: #666;	
		}
	}
}

// Barra footer
.barra-footer {
	position: absolute;
	left: 0;
	bottom: 0;
	float: left;
	width: 100%;
	background: darken($cor_fundo2, 2%);
	padding: 10px 0;
	font-family: $fonte-geral;
	font-size: 15px;
	color: $cor_fonte_escura;

	// MOBILE
	@include mq(s) {
		position: static;
		margin-top: 40px;	
	}

	.left {
		text-align: left;
		// MOBILE
		@include mq(s) {
			text-align: center !important;	
		}
	}

	.center {
		text-align: center;
	}

	.right {
		text-align: right;
		margin-top: 12px;
		color: #666;
	}

	[class^="grid-"] {
		@include mq(s) {
			text-align: center !important;
			margin-bottom: 10px;
		}
	}

	a {
		color: $cor_fonte_escura;

		&:hover {
			color: $cor_primaria;
		}
	}	

	// Da um padding-top nos textos para alianhar as redes sociais
	.grid-4:not(.redes-sociais) {
		padding-top: 12px;
	}
}

// Redes sociais
.redes-sociais {
	float: left;
	width: auto;
	margin-top: -10px;

	// MOBILE
	@include mq(s) {
		width: 100%;
		margin-top: 0px;
		text-align: center;
	}

	// Item
	a {
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 43px;
		border-radius: 50%;
		border: 1px solid $cor_primaria;
		text-align: center;
		margin-right: 5px;
		transition: .3s ease-out;

		&:hover {
			background: $cor_primaria;
		}

		i {
			color: $cor_primaria;
			font-size: 20px;
			transition: .3s ease-out;
		}
		&:hover i {
			color: darken($cor_fundo2, 2%);
		}
	}
}