// Formulário
#form-contato {
	padding-left: 20px;

	// TABLET
	@include mq(m) {
		padding-left: 10px;
	}

	// MOBILE
	@include mq(s) {
		padding-left: 0px;
	}

	// Texto
	.texto {
		font-size: 18px;
		line-height: 26px;
		margin-bottom: 40px;
	}
}

// Lateral
.contato-lateral {
	height: 485px;
	background: $cor_fundo2;
	border-top: 3px solid $cor_primaria;
	padding: 40px;

	// TABLET
	@include mq(m) {
		height: auto;
		padding: 20px;
	}
	
	// MOBILE
	@include mq(s) {
		height: auto;
		padding: 20px;
		margin-bottom: 40px;
	}
}

// Lateral item
.contato-item {
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 0px;
	}

	// Icone
	.contato-item-icon {
		text-align: center;

		i {
			font-size: 28px;
			color: $cor_primaria;

			// TABLET
			@include mq(m) {
				font-size: 20px;
			}
		}
	}

	// Título
	h2 {
		font-family: $fonte_titulo;
		font-size: 16px;
		line-height: 24px;
		color: $cor_fonte_escura;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	// Texto 
	p {
		margin-bottom: 5px;
		color: $cor_fonte_clara;
		font-weight: 300;
		font-size: 16px;
		line-height: 24px;
	}

	// Link
	a {
		color: $cor_fonte_clara !important;
		font-weight: 300;
		font-size: 16px;
		line-height: 24px;
	}
}

// Mapa
.contato-mapa {
	float: left;
	width: 100%;
	height: 350px;
}

//Segura mapa abaixo form
.segura-mapa-contato{
	margin-top: 40px;
	iframe{
		width: 100%;
		height: 450px;

		// MOBILE
		@include mq(s) {
			height: 350px;
		}

		// TABLET
		@include mq(s) {
			height: 350px;
		}
	}
}
